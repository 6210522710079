import React from 'react';
import styles from './Welcome.module.css';

function Welcome() {
  return (
      <div className={styles.welcome}>
        <h1>Project Slim + React</h1>
        <p>We will be here soon</p>
      </div>
  );
}

export default Welcome;
